<template>
	<div>
		<div class="listTitle">
			<div class="goodsTitle">商品</div>
			<div>数量</div>
			<div>商品单价</div>
			<div>订单状态</div>
			<div>应付金额</div>
			<div>订单操作</div>
		</div>
		<div class="list">
			<div class="item" v-for="(order, index) in orderList" :key="index">
				<div class="detail">
					<div>订单号：{{ order.order_id }}</div>
					<div>下单时间：{{ order._add_time }}</div>
					<div>应付总额：{{ order.pay_price }}</div>
				</div>
				<div class="orderDetail" v-for="cart in order.cartInfo" :key="cart.id">
					<div class="goodsContent">
						<img :src="cart.productInfo.image" />
						<div>
							<div class="name">{{ cart.productInfo.store_name }}</div>
							<div>{{ cart.productInfo.attrInfo.suk }}</div>
						</div>
					</div>
					<div>{{ cart.cart_num }}</div>
					<div>￥{{ cart.productInfo.attrInfo ? cart.productInfo.attrInfo.price : cart.productInfo.price }}</div>
					<div>{{ getStatus(order) }}</div>
					<div></div>
					<div class="operate">
						<!-- <router-link class="payNow" @click="paymentTap(order)" to="payment">立即付款</router-link> -->
						<div class="payNow" @click="$router.push({ name: 'Payment', params: { orderId: order.order_id } })">立即付款</div>
						<div class="cacle" @click="cancelOrder(order)">取消订单</div>
					</div>
				</div>
			</div>
		</div>
		<div class="noCart" v-if="orderList.length === 0"><img src="@assets/images/noOrder.png" /></div>
		<el-pagination
			:hide-on-single-page="true"
			@size-change="SizeChange"
			@current-change="CurrentChange"
			:current-page="queryParam.page"
			:page-size="queryParam.limit"
			:page-sizes="[2, 20, 30, 50]"
			layout="total, sizes, prev, pager, next, jumper"
			:total="orderData.unpaid_count"
		></el-pagination>
	</div>
</template>

<script>
const STATUS = ['待付款', '待发货', '待收货', '待评价', '已完成', '', '', '', '', '待付款'];
export default {
	name: 'OrderList',
	props: {
		orderList: {
			type: Array,
			default: () => []
		},
		orderData: {
			type: Number,
			default: () => {}
		},
		queryParam: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {};
	},
	methods: {
		// 订单状态
		getStatus(order) {
			return STATUS[order._status._type];
		},
		SizeChange: function(e) {
			this.$emit('SizeChange', e);
		},
		CurrentChange: function(e) {
			this.$emit('CurrentChange', e);
		},
		cancelOrder: function(order) {
			this.$emit('cancelOrder', order);
		}
	}
};
</script>

<style lang="less" scoped>
.listTitle {
	background: #f6f6f6;
	color: #999;
	height: 40px;
	line-height: 46px;
	font-size: 12px;
	display: flex;
	justify-content: space-around;
	div {
		flex: 1;
		text-align: center;
	}
	div.goodsTitle {
		flex: 4;
	}
}
.list {
	font-size: 12px;
	.item {
		margin-top: 14px;
		border: 1px solid #f6f6f6;
		.detail {
			background-color: #f6f6f6;
			color: #666;
			display: flex;
			justify-content: space-around;
			height: 40px;
			line-height: 40px;
		}
		.orderDetail {
			display: flex;
			justify-content: space-around;
			padding: 10px 0;
			align-items: center;
			div {
				flex: 1;
				text-align: center;
			}
			div.goodsContent {
				flex: 4;
				display: flex;
				align-items: flex-start;
				img {
					width: 100px;
					height: 100px;
					margin: 0 12px;
				}
				div {
					text-align: left;
				}
			}
			div.operate {
				cursor: pointer;
				.payNow {
					background: #f60;
					width: 100px;
					height: 36px;
					line-height: 36px;
					border-radius: 4px;
					margin: 10px;
					color: #fff;
					display: block;
				}
			}
		}
	}
}
.noCart {
	text-align: center;
	img {
		width: 260px;
		margin: 40px auto;
	}
}
</style>
