<template>
	<div>
		<div class="title">我的订单</div>
		<el-form class="search_box" ref="searchForm" :model="searchForm" label-width="80px" size="mini">
			<el-form-item class="searchItem">
				<el-input :placeholder="placeholder" v-model="searchForm.orderNumber" class="input-with-select">
					<el-select v-model="searchForm.numberSlect" slot="prepend" placeholder="请选择">
						<el-option :label="item.label" :value="item.value" v-for="(item, index) in numberType" :key="index"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item class="searchItem">
				<el-input :placeholder="placeholder" v-model="searchForm.receiverValue" class="input-with-select">
					<el-select v-model="searchForm.receiverSlect" slot="prepend" placeholder="请选择">
						<el-option :label="item.label" :value="item.value" v-for="(item, index) in receiverType" :key="index"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item class="searchItem" style="margin-right: 0;">
				<el-input :placeholder="placeholder" v-model="searchForm.goodsValue" class="input-with-select">
					<el-select v-model="searchForm.goodsSlect" slot="prepend" placeholder="请选择">
						<el-option :label="item.label" :value="item.value" v-for="(item, index) in goodsType" :key="index"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item label="下单时间">
				<el-date-picker v-model="searchForm.timeValue" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">搜索</el-button>
				<el-button>重置</el-button>
			</el-form-item>
		</el-form>
		<el-tabs v-model="queryParam.type" @tab-click="tabNav" v-loading="loading">
			<!-- 全部订单 -->
			<!-- <el-tab-pane label="全部订单" name=""><div>全部订单</div></el-tab-pane> -->
			<!-- 待付款 -->
			<el-tab-pane label="待付款" name="0">
				<Order-list
					:orderList="orderList"
					:orderData="orderData.unpaid_count"
					:queryParam="queryParam"
					@SizeChange="SizeChange($event)"
					@CurrentChange="CurrentChange($event)"
					@cancelOrder="cancelOrder($event)"
				></Order-list>
			</el-tab-pane>
			<!-- 待发货 -->
			<el-tab-pane label="待发货" name="1">
				<Order-list
					:orderList="orderList"
					:orderData="orderData.unshipped_count"
					:queryParam="queryParam"
					@SizeChange="SizeChange($event)"
					@CurrentChange="CurrentChange($event)"
					@cancelOrder="cancelOrder($event)"
				></Order-list>
			</el-tab-pane>
			<!-- 待收货 -->
			<el-tab-pane label="待收货" name="2">
				<Order-list
					:orderList="orderList"
					:orderData="orderData.received_count"
					:queryParam="queryParam"
					@SizeChange="SizeChange($event)"
					@CurrentChange="CurrentChange($event)"
					@cancelOrder="cancelOrder($event)"
				></Order-list>
			</el-tab-pane>
			<!-- 待评价 -->
			<el-tab-pane label="待评价" name="3">
				<Order-list
					:orderList="orderList"
					:orderData="orderData.evaluated_count"
					:queryParam="queryParam"
					@SizeChange="SizeChange($event)"
					@CurrentChange="CurrentChange($event)"
					@cancelOrder="cancelOrder($event)"
				></Order-list>
			</el-tab-pane>
			<!-- 已完成 -->
			<el-tab-pane label="已完成" name="4">
				<Order-list
					:orderList="orderList"
					:orderData="orderData.complete_count"
					:queryParam="queryParam"
					@SizeChange="SizeChange($event)"
					@CurrentChange="CurrentChange($event)"
					@cancelOrder="cancelOrder($event)"
				></Order-list>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { getOrderData, getOrderList } from '@api/user';
import { cancelOrderHandle } from '@api/order';
import OrderList from '@components/OrderList';
export default {
	name: 'MyOrder',
	components: {
		OrderList
	},
	data() {
		return {
			placeholder: '请输入搜索内容',
			numberType: [
				{
					label: '订单号',
					value: '1'
				},
				{
					label: '支付单号',
					value: '2'
				}
			],
			receiverType: [
				{
					label: '收货人姓名',
					value: '1'
				},
				{
					label: '收货人手机号',
					value: '2'
				}
			],
			goodsType: [
				{
					label: '商品编码',
					value: '1'
				},
				{
					label: '商名称',
					value: '2'
				}
			],
			searchForm: {
				orderNumber: '',
				numberSlect: '1',
				receiverValue: '',
				receiverSlect: '1',
				goodsValue: '',
				goodsSlect: '1',
				timeValue: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)]
			},
			queryParam: {
				page: 1,
				limit: 2,
				type: 0
			},
			orderList: [],
			orderData: {
				unpaid_count: '',
				unshipped_count: '',
				received_count: '',
				evaluated_count: '',
				complete_count: ''
			},
			loading: false
		};
	},
	mounted() {
		let that = this;
		that.getOrderList();
		that.getOrderData();
	},
	methods: {
		// 订单列表
		getOrderList() {
			let that = this;
			getOrderList(that.queryParam).then(res => {
				that.orderList = res.data;
				that.loading = false;
			});
		},
		// 订单统计数据
		getOrderData() {
			let that = this;
			getOrderData().then(res => {
				that.orderData = res.data;
			});
		},
		// tab跳转
		tabNav(e) {
			let that = this;
			that.queryParam.page = 1;
			that.queryParam.type = e.name;
			that.loading = true;
			that.getOrderList();
		},
		CurrentChange(e) {
			this.queryParam.page = e;
			this.getOrderList();
		},
		SizeChange(e) {
			this.queryParam.limit = e;
			this.getOrderList();
		},
		// 取消订单
		cancelOrder(order) {
			cancelOrderHandle(order.order_id)
				.then(() => {
					this.getOrderData();
					this.orderList.splice(this.orderList.indexOf(order), 1);
				})
				.catch(() => {
					this.reload();
				});
		},
		// 立即付款
		// paymentTap: function(order) {
		// 	var that = this;
		// 	that.$router.push({ path: '/payment', query: order })
		// }
	}
};
</script>

<style lang="less" scoped>
.title {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 10px;
}
.search_box {
	display: flex;
	flex-wrap: wrap;
	.searchItem {
		margin-right: 80px;
	}
}
</style>
